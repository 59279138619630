<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="card">
      <div class="card_logo">
        <img :src="loadPoster()" alt="" />
      </div>
      <div class="card_welcomeText">
        <p class="card_welcomeText_primary_text">{{ $t("welcome to noorplay") }}</p>
        <p class="card_welcomeText_secondary_text" v-if="!isZainJordan">
          {{
            $t(`Try ${plan.trialperioddays} ${plan.trialperioddays === 1 ? "day" : "days"} for free. Cancel anytime`)
          }}
        </p>
      </div>
      <div class="card_inputs">
        <div>
          <div
            class="card_inputs_cards"
            :class="selectedIndex === index ? 'active_input' : ''"
            v-for="(item, index) in plans"
            :key="index"
            @click="planSelect(item, index)"
          >
            <div :class="localDisplayLang === 'ara' ? 'radio_container_right' : 'radio_container'">
              <label class="radio_label" :for="`${item.planinterval}`"
                >{{ $t(`${item.planinterval}`) }}
                <input type="radio" :id="`${item.planinterval}`" :value="item" v-model="plan" />
                <span :class="localDisplayLang === 'ara' ? 'radiomark_check_right' : 'radiomark_check'"></span>
              </label>
            </div>
            <p class="card_inputs_cards_pricetag">{{ `${item.currency}` + " " + calculatePrice(item) }}</p>
          </div>
        </div>
      </div>

      <div class="card_button">
        <button @click="startFreeTrail()" v-if="!btnLoader">{{ buttonText }}</button>
        <Loading v-else></Loading>
      </div>
      <div class="card_info">
        <p class="primary">
          {{ notCustomer }} <span class="click" @click="exitPage()">{{ $t("Click here") }}</span>
        </p>
        <p class="secondary">{{ planTextLocale(plan) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus.js";
import { mapActions } from "vuex";
import { store } from "@/store/store";
import googleAnalytics from "@/mixins/googleAnalytics.js";

export default {
  props: {
    planList: {
      type: Array,
    },
    selectedGateway: {
      type: Object,
    },
  },
  data() {
    return {
      days: 7,
      inputs: [],
      plans: [],
      plan: null,
      routename: null,
      selectedIndex: null,
      localDisplayLang: null,
      btnLoader: false,
    };
  },
  computed: {
    isZain() {
      return this.routename === "stc-bahrain" ? false : true;
    },
    isZainJordan() {
      if (this.routename === "zain-jordan") {
        return true;
      } else {
        return false;
      }
    },
    notCustomer() {
      if (this.routename === "stc-bahrain") {
        return this.$t("Not a STC customer?");
      } else {
        return this.$t("Not a ZAIN customer?");
      }
    },
    buttonText() {
      if (this.routename === "stc-bahrain") {
        return this.$t("Start Free Trial");
      } else {
        return this.$t("Subscribe");
      }
    },
  },
  created() {
    this.routename = this.$router.currentRoute.params.id;

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.loadPlans();

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  methods: {
    ...mapActions(["paymentInitiation"]),
    loadPlans() {
      this.plans = this.planList;
      this.planList.forEach((element, index) => {
        if (element.planinterval === "MONTH") {
          this.selectedIndex = index;
          this.plan = this.planList[index];
        }
      });
    },

    loadPoster() {
      if (this.routename === "stc-bahrain") {
        return require("@/assets/icons/STC.svg");
      } else {
        return require("@/assets/icons/zain-poster.png");
      }
    },

    calculatePrice(item) {
      return item.amount / 100;
    },

    planTextLocale(plan) {
      // STC - Bahrain - Week
      if (plan.currency === "BHD" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Once your 2 days free trial ends, you will be charged BHD 0.7 weekly. Renewals will be automatic every weekly. To cancel the service anytime, just send ‘’STOP NPW” to 98797. Free trial is applicable for new customers only`;
      }
      // STC - Bahrain - Month
      else if (plan.currency === "BHD" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Once your 7 days free trial ends, you will be charged BHD 2 monthly. Renewals will be automatic every monthly. To cancel the service anytime, just send ‘’STOP NPM” to 98797. Free trial is applicable for new customers only`;
      }
      // Zain - Kuwait - Week
      else if (plan.currency === "KWD" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Once your 2 days free trial ends, you will be charged KWD 0.5 weekly. Renewals will be automatic every weekly. To cancel the service anytime, just send ‘’UNSUB NPW” to 93502. Free trial is applicable for new customers only`;
      }
      // Zain - Kuwait - Month
      else if (plan.currency === "KWD" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Once your 7 days free trial ends, you will be charged KWD 1.5 monthly. Renewals will be automatic every monthly. To cancel the service anytime, just send ‘’UNSUB NPM” to 93502. Free trial is applicable for new customers only`;
      }
      // Zain - Jordan - Month
      else if (plan.currency === "JOD" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `You will be charged JOD 3 monthly. 
              Renewals will be automatic every monthly. 
              To cancel the service anytime, just send 
              UNSUB NPM to 97970`;
      }
      // Zain - Jordan - Week
      else if (plan.currency === "JOD" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `You will be charged JOD 1 weekly. 
              Renewals will be automatic every Weekly. 
              To cancel the service anytime, just send 
              UNSUB NPW to 97970`;
      }
      // Zain - Jordan - Day
      else if (plan.currency === "JOD" && this.localDisplayLang === "eng" && plan.planinterval === "DAY") {
        return `You will be charged JOD 0.2 Daily. 
              Renewals will be automatic every day. 
              To cancel the service anytime, just send 
              UNSUB NPD to 97970`;
      }
      // STC - Bahrain - Week
      else if (plan.currency === "BHD" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `عند إنتهاء الفترة التجريبية ( 2 يوم ) سيتم خصم 0.7 دينار بحريني أسبوعياً وسيتم تجديد الإشتراك تلقائياً كل أسبوع
          بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP NPW" للرقم 98797
          "التجربة المجانية متوفرة للمشتركين الجدد فقط"`;
      }
      // STC - Bahrain - Month
      else if (plan.currency === "BHD" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 2 دينار بحريني شهرياً وسيتم تجديد الإشتراك تلقائياً كل شهر
          بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP NPM" للرقم 98797
          "التجربة المجانية متوفرة للمشتركين الجدد فقط"`;
      }
      // Zain - Kuwait - Week
      else if (plan.currency === "KWD" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `عند إنتهاء الفترة التجريبية ( 2 أيام ) سيتم خصم 0.5 دينار كويتي أسبوعياً وسيتم تجديد الإشتراك تلقائياً كل أسبوع
            بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB NPW" للرقم 93502
            التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      }
      // Zain - Kuwait - Month
      else if (plan.currency === "KWD" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 1.5 دينار كويتي شهرياً وسيتم تجديد الإشتراك تلقائياً كل شهر
            بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB NPM" للرقم 93502
            التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      }
      //
      else if (plan.currency === "JOD" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `سيتم احتساب رسوم الخدمة ( 3 دينار أردني ) شهرياً
              سيتم تجديد الإشترك بشكل تلقائي كل شهر
              بإمكانك إلغاء الإشتراك في أي وقت مجانا عبر إرسال
              UNSUB NPM للرقم 97970`;
      }
      //
      else if (plan.currency === "JOD" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `سيتم احتساب رسوم الخدمة ( 1 دينار أردني ) أسبوعياً
              سيتم تجديد الإشترك بشكل تلقائي كل أسبوع
              بإمكانك إلغاء الإشتراك في أي وقت مجانا عبر إرسال
              UNSUB NPW للرقم 97970`;
      }
      //
      else if (plan.currency === "JOD" && this.localDisplayLang === "ara" && plan.planinterval === "DAY") {
        return `سيتم احتساب رسوم الخدمة ( 0.2 دينار أردني ) يومياً
              سيتم تجديد الإشترك بشكل تلقائي كل يوم
              بإمكانك إلغاء الإشتراك في أي وقت مجانا عبر إرسال
              UNSUB NPD للرقم 97970`;
      }
    },

    planSelect(item, index) {
      this.selectedIndex = index;
      this.plan = item;
    },

    createFecthApiForSTCPaymentInit(payload) {
      return store.dispatch("telcoPaymentInititation", payload);
    },

    setSubmitAnalytics() {
      const data = {
        mobile: "",
      };

      console.log("The Mobile number Analytics", data);

      this.telco_page_submission_tracker(data);
    },

    startFreeTrail() {
      this.setSubmitAnalytics();

      let currentDomain = window.location.origin;

      let payload = {
        gatewayid: this.selectedGateway.gatewayid,
        params: {
          amount: this.plan.amount,
          currency: this.plan.currency,
          devicetype: "WEB",
          transactionpurpose: "SUBSCRIPTION",
          transactionmode: "CC",
          availabilityid: this.plan.availabilityset[0],
          planid: this.plan.planid,
          displaylanguage: this.localDisplayLang
        },
      };

      let routeLocale = this.localDisplayLang === "ara" ? "ar" : "en";

      if (this.routename === "stc-bahrain") {
        payload.params.return_url = currentDomain + `/${routeLocale}/telcoPaymentHandler`;
      } else {
        payload.params.client_return_url = currentDomain + `/${routeLocale}/telcoPaymentHandler`;
      }

      this.btnLoader = true;

      console.log("start free trail payload", payload);

      this.createFecthApiForSTCPaymentInit(payload)
        .then((response) => {
          this.btnLoader = false;
          console.log("payment Initiation response", response);

          let redirectURL = "";
          if (response.referencedata.paymenturl) {
            redirectURL = response.referencedata.paymenturl + `?&lang=${this.localDisplayLang === "ara" ? "ar" : "en"}`;
          } else if (response.referencedata.checkoutUrl) {
            redirectURL = response.referencedata.checkoutUrl;
          }

          window.open(redirectURL, "_self");
        })
        .catch((error) => {
          console.log("payment initiation error", error);
        });

      let data = {
        telco_partner: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
        plan_name: this.plan.planname,
        plan_id: this.plan.planid,
      };
      this.telco_trial_initiated(data);

      localStorage.setItem("TelcoPlan", JSON.stringify(data));
    },

    exitPage() {
      return this.$router.push("/profile");
    },

    // redirect() {
    //   this.$emit("redirect");
    // },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [googleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.card {
  background: #ffffff1f 0% 0% no-repeat padding-box;
  border: 1px solid #404040;
  border-radius: 14px;
  width: 330px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &_logo {
    img {
      // width: 97px;
      height: 48px;
    }
  }
  &_welcomeText {
    margin: 10px;
    color: #ffffff;
    font-family: $font-helvetica-medium;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    &_primary_text {
      font-size: 1.2rem;
    }
    &_secondary_text {
      font-size: 0.6rem;
    }
  }
  &_inputs {
    margin: 10px;
    &_cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      padding: 5px 10px;
      margin-bottom: 10px;
      width: 250px;
      cursor: pointer;
      &_pricetag {
        color: #ffffff;
        font-family: $font-helvetica-medium;
        font-weight: 400;
        font-size: 0.7rem;
      }
      &:hover {
        border: 1px solid #aa1a62;
        background: #aa1a622c;
      }
    }
  }
  &_button {
    button {
      background: transparent linear-gradient(180deg, #9d1153 0%, #cd154e 100%) 0% 0% no-repeat padding-box;
      border-radius: 7px;
      border: none;
      padding: 10px 15px;
      color: #ffffff;
      font-family: $font-regular;
      cursor: pointer;
    }
  }
  &_info {
    margin-top: 20px;
    font-family: $font-helvetica-medium;
    font-weight: 400;
    font-size: 0.6rem;
    text-align: center;
    .primary {
      color: #b7b7b7;
      margin-bottom: 10px;
    }
    .click {
      cursor: pointer;
      color: #aa1a62;
    }
    .secondary {
      color: #b7b7b7;
      line-height: 0.8rem;
      //white-space: pre-line;
      text-align: center;
    }
  }
}

.active_input {
  border: 1px solid #aa1a62;
  background: #aa1a622c;
}

/* CSS classes for left alignment*/

/* Customize the label (the container) */
.radio_container {
  display: block;
  position: relative;
  padding-left: 25px;
  // margin-top: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio_label {
  color: #ffffff;
}

/* Hide the browser's default radio */
.radio_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio */
.radiomark_check {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.radio_container:hover input ~ .radiomark_check {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.radio_container input:checked ~ .radiomark_check {
  background-color: #aa1a62;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiomark_check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio_container input:checked ~ .radiomark_check:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio_container .radiomark_check:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* CSS classes for right alignment*/

/* Customize the label (the container) */
.radio_container_right {
  display: block;
  position: relative;
  padding-right: 25px;
  // margin-top: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio_container_right input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.radiomark_check_right {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.radio_container_right:hover input ~ .radiomark_check_right {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.radio_container_right input:checked ~ .radiomark_check_right {
  background-color: #aa1a62;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiomark_check_right:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio_container_right input:checked ~ .radiomark_check_right:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio_container_right .radiomark_check_right:after {
  right: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 576px) {
  .card {
    width: 100%;
    // border-radius: 0;
  }
}
</style>
